import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from 'gatsby';
import './quickbooks-integration.less';
import { appUrl } from '../../helpers/helpers'

import phoneicon from '../../images/produceplan-iphoneicon.svg';
import howItWorksImg from '../../images/PP-QB-Flow.svg';
import buyBuild from '../../images/BuyBuild.svg';
import getFreight from '../../images/GetFreight.svg';
import sellInvoice from '../../images/SellInvoice.svg';
import trackReconcile from '../../images/TrackReconcile.svg';
import saveSync from '../../images/SaveSync.svg';

const signupUrl = `${appUrl}/signup?redirect=wizardGrower`;
const ctaUrl = `${appUrl}/orders/new?source=produceplan.com`;

const ForSuppliersPage = () => (
  <Layout headerColor="#BFCEFE">
    <SEO title="Suppliers" />
    <div id="home-b">
      <div className="home-panel" id="hero-b">
        <div className="container">
          <div id="hero-b-left">
            <h2>
              Produceplan™ + accounting<br />
              w/ integrations & more
            </h2>
            <p>
              Let your sales team sell profitably—whenever, wherever. Once your load delivers,
              save and sync your data instantly with our Quickbooks integration.
            </p>
            <p>
              Track & trace costs by vendor, grower, and line item to get real time insights into
              load profitability BEFORE product leaves your dock. Markup, track, and trace costs 
              on a lot by lot basis.
            </p>
            <div id="cta-buttons">
              <a
                href={ctaUrl}
                className="btn btn-green btn-shadowless btn-rounded green"
              >
                Get a full virtual tour
              </a>
              <a
                href={signupUrl}
                className="btn orange btn-shadowless btn-rounded"
              >
                Sign Up
              </a>
            </div>
          </div>
          <div id="hero-b-right">
            <img
              src={phoneicon}
              alt="Produceplan"
            />
          </div>
        </div>
      </div>
    </div>    

    <div className="for-suppliers-wrapper" style={{ position: 'relative' }}>
      <div className="container" id="forSuppliers">
        <div className="row">
          <div className="col s12">
            <h4 style={{fontWeight:600}}>How it works</h4>
            <p>
              Whether it’s building an order, selling, invoicing, or creating inventory data, 
              we offer debits and credits for smooth data syncing.
            </p>
          </div>
        </div>
        <div className="how-it-works">
          <img src={howItWorksImg} alt="How it works" className="how-it-works-img" />

          <div className="how-it-works-text buy-build">
            <img src={buyBuild} alt="Buy or Build" className="how-it-works-text-img" />
            <h6>
              Buy or Build
            </h6>
            <p>
              Create purchase orders or build lots of inventory by vendor and grower
            </p>
          </div>
          <div className="how-it-works-text get-freight">
            <img src={getFreight} alt="Get Freight" className="how-it-works-text-img" />
            <h6>
            Get Freight
            </h6>
            <p>
            Use our cloud based TMS to find trucks, cost your order, issue order confirmations, and sync driver contact info
            </p>
          </div>
          <div className="how-it-works-text sell-invoice">
            <img src={sellInvoice} alt="Sell & Invoice" className="how-it-works-text-img" />
            <h6>
              Sell & Invoice
            </h6>
            <p>
              Preview order profitability, send sales confirmations, invoices, and customer statements in real time
            </p>
          </div>
          <div className="how-it-works-text track-reconcile">
            <img src={trackReconcile} alt="Track & Reconcile" className="how-it-works-text-img" />
            <h6>
              Track & Reconcile
            </h6>
            <p>
              Manage final adjustments, liquidations, and multiple pick up and drop off points to finalize your order info
            </p>
          </div>
          <div className="how-it-works-text save-sync">
            <img src={saveSync} alt="Save & Sync" className="how-it-works-text-img" />
            <h6>
              Save & Sync
            </h6>
            <p>
              Import & export invoices, vendor bills, and inventory items with your Quickbooks software. <br/><br/> 
              Or, use our debits and credits feature to sync with other accounting systems!
            </p>
          </div>
        </div>
      </div>
    </div>
  
    <div className="row partner-cards">
      <div className="center">
        <a href={ctaUrl} className="btn blue-grey darken-3 btn-cta">Get a full virtual tour</a>
        <span className="btn-row-or"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; or &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
        <a href={signupUrl} className="btn orange btn-cta">Sign Up</a>
      </div>
    </div>
  </Layout>
)

export default ForSuppliersPage
